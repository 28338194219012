<template>
  <div
    class="flex flex-col space-y-1 text-sm text-gray-500"
    :class="{ 'mb-5': !customBottomMargin }"
  >
    <InputLabel v-if="label" :id="id || name" :required="required">
      {{ label }}
    </InputLabel>
    <InputBlocked v-if="blocked" :blockedData="blockedData" @click="emit('blockedLabelClick')" />
    <div v-else class="flex flex-col space-y-1">
      <div class="relative flex items-center">
        <span class="absolute top-0 left-3 h-full flex items-center">
          <slot name="preInput"></slot>
        </span>
        <input
          :id="id"
          ref="inputEl"
          :type="type"
          :step="step"
          :min="min"
          :maxlength="maxLength"
          :name="name"
          :placeholder="placeholder"
          :lang="userLang"
          :value="inputValue"
          :autocomplete="autocomplete"
          class="w-full border border-gray-300 hover:border-gray-400 placeholder-gray-300 rounded text-gray-500 font-medium text-xs p-3 shadow-sm focus-within:shadow-blue-100 focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-blue-300"
          :class="[
            compact ? 'h-9' : 'h-12',
            disabled ? 'bg-gray-50 cursor-not-allowed' : 'bg-white hover:border-gray-400',
            {
              'border-red-300 hover:border-red-400': error,
            },
            inputClass,
          ]"
          :aria-invalid="!!error"
          :disabled="disabled"
          :required="required"
          @focus="handleFocus"
          @blur="handleBlur"
          @input.stop="onInput"
        />
        <div class="absolute right-3 h-full top-0 flex items-center">
          <slot name="postInput"></slot>
          <LockedSolidIcon v-if="disabled" :class="compact ? 'w-4 h-4' : 'w-5 h-5'" />
          <CopyToClipboardButton
            v-if="copy"
            :copy-value="inputValue"
            class="hover:text-gray-600"
            :class="compact ? 'w-4 h-4' : 'w-5 h-5'"
          />
          <TooltipButton
            v-if="tooltip && !disabled"
            :tooltip="tooltip"
            :class="compact ? 'w-4 h-4' : 'w-5 h-5'"
          />
        </div>
      </div>
      <ErrorMessage v-if="error">{{ error }}</ErrorMessage>
      <HelpMessage v-if="helpText">{{ helpText }}</HelpMessage>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { userLang } from "@/helpers/globals"
import InputLabel from "./_base/InputLabel.vue"
import InputBlocked from "./_base/InputBlocked.vue"
import ErrorMessage from "./_base/ErrorMessage.vue"
import HelpMessage from "./_base/HelpMessage.vue"
import TooltipButton from "./_base/TooltipButton.vue"
import { LockedSolidIcon } from "@/components/icons"
import CopyToClipboardButton from "./_base/CopyToClipboardButton.vue"

const props = defineProps({
  name: String,
  id: String,
  type: String,
  placeholder: String,
  modelValue: [String, Number],
  compact: Boolean,
  error: [String, Boolean],
  label: String,
  required: Boolean,
  disabled: Boolean,
  characterLimit: Number,
  step: {
    type: Number,
    default: () => 1,
  },
  min: {
    type: Number,
    default: () => 0,
  },
  tooltip: [String, Object],
  copy: Boolean,
  autocomplete: String,
  maxLength: Number,
  helpText: String,
  customBottomMargin: Boolean,
  inputClass: String,
  blocked: Boolean,
  blockedData: Object,
})
const emit = defineEmits(["update:modelValue", "focus", "blur", "blockedLabelClick"])

const inputEl = ref()
const inputValue = ref(props.modelValue)

watch(
  () => props.modelValue,
  newVal => {
    inputValue.value = newVal
  },
)
const onInput = event => {
  let value = event.target.value
  if (props.characterLimit) {
    value = value.substring(0, props.characterLimit)
    event.target.value = value
  }
  inputValue.value = value
  emit("update:modelValue", inputValue.value)
}
const handleFocus = () => {
  emit("focus")
}
const handleBlur = () => {
  emit("blur")
}
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
